<template>
  <div id="PTpage">
    <header id="header">
      <div id="PTheadWrap">
        <ul class="coninpointPT">
          <li>
            <a @click="goPageByName('main')" class="PTLogo">
              <!--<img src="@/assets/img/batmanlogo.svg" />--> 〈〈 USER PAGE
            </a>
          </li>
          <li>
            <h1 class="PTpageName">파트너 페이지</h1>
          </li>
        </ul>
        <ul class="coninpointPT" v-if="userData">
          <li><h2 class="PTmemName"><span>{{userData.memNick}}</span> {{$t('front.stributor.strWelcome')}}</h2></li>
          <li><img src="@/assets/img/hcoin.svg" /><span>{{thousand(userData.cashAmt ? userData.cashAmt.toString(): '0')}}</span></li>
          <li style="cursor: pointer" @click="goPageByName('point')"><img src="@/assets/img/hpoint.svg" /><span>{{thousand(userData.pointAmt ? userData.pointAmt.toString() : '0')}}</span></li>
          <li class="mymsgsPT" @click="goPageByName('message')">
            <em><img src="@/assets/img/hmsg.svg"></em>
            <span class="PTmsg">{{thousand(unReadMessageCount ? unReadMessageCount.toString() : '0')}}</span>
          </li>
        </ul>
      </div>
    </header>
    <section id="PTbodyWrap">
      <div class="PTboardwrap">
        <div class="w100w">
          <div class="PTtabWrap">
            <ul style="display:flex; cursor:pointer;">
              <li class="" data-tab="tab-5" @click="onChangeTab('PTtab-5')" :class="{'current': currentTab == 'PTtab-5'}">
                <div>
                  <span class=" PTtab1img PTtabimg"></span>
                  <h3>입·출금내역<p>Calculation</p></h3>
                </div>
              </li>
              <li class="" data-tab="tab-6" @click="onChangeTab('PTtab-6')" :class="{'current': currentTab == 'PTtab-6'}">
                <div>
                  <span class=" PTtab1img PTtabimg"></span>
                  <h3>베팅내역<p>Calculation</p></h3>
                </div>
              </li>
              <li class="" data-tab="tab-1" @click="onChangeTab('PTtab-1')" :class="{'current': currentTab == 'PTtab-1'}">
                <div>
                  <span class=" PTtab1img PTtabimg"></span>
                  <h3>파트너정산내역<p>Calculation</p></h3>
                </div>
              </li>
              <li class="" data-tab="tab-4" @click="onChangeTab('PTtab-4')" :class="{'current': currentTab == 'PTtab-4'}">
                <div>
                  <span class=" PTtab1img PTtabimg"></span>
                  <h3>유저정산내역<p>Calculation</p></h3>
                </div>
              </li>
              <li class="" data-tab="tab-2" @click="onChangeTab('PTtab-2')" :class="{'current': currentTab == 'PTtab-2' , 'disabled': userData.partnerLevel === 'PTN_5'}">
                <div>
                  <span class=" PTtab2img PTtabimg"></span>
                  <h3>하부파트너목록<p>Partner List</p></h3>
                </div>
              </li>
              <li class="" data-tab="tab-3" @click="onChangeTab('PTtab-3')" :class="{'current': currentTab == 'PTtab-3'}">
                <div>
                  <span class=" PTtab2img PTtabimg"></span>
                  <h3>소속회원목록<p>User List</p></h3>
                </div>
              </li>
              <li class="" data-tab="tab-7" @click="onChangeTab('PTtab-7')" :class="{'current': currentTab == 'PTtab-7'}">
                <div>
                  <span class=" PTtab1img PTtabimg"></span>
                  <h3>회원머니지급<p>User Money</p></h3>
                </div>
              </li>
            </ul>
          </div>
          <!--<div class="stribuname"><span>{{userData.memNick}}</span> {{$t('front.stributor.strInfo')}}</div>-->
          <div class="currentPT">
            <ul class="strheadPT">
              <li><h3 class="pagename2">현황</h3></li>
              <!--<li><img src="@/assets/img/allUserso.png" alt=""><span>{{$t('front.stributor.Alluser')}}</span><span class="strnum">{{thousand((summary  && summary['COUNT(*)']) || 0)}}</span></li>
              <li><img src="@/assets/img/setting_completeo.png" alt=""><span>{{$t('front.stributor.rateComplet')}}</span><span class="strnum">{{thousand( (summary && summary['COUNT(*)'] || 0) - (summary && summary.notSetCnt || 0) )}}</span></li>
              <li><img src="@/assets/img/setting_incompleteo.png" alt=""><span>{{$t('front.stributor.rateIncomplete')}}</span><span class="strnum">{{thousand(summary && summary.notSetCnt || 0)}}</span></li>
              <li><img src="@/assets/img/pointo.png" alt=""><span>{{$t('front.stributor.allPoint')}}</span><span class="strnum">{{thousand(summary && summary.allpoinAmt || 0)}}</span></li>
              <li><img src="@/assets/img/usePointo.png" alt=""><span>{{$t('front.stributor.usePoint')}}</span><span class="strnum">{{thousand(summary && summary.usePoinAmt || 0)}}</span></li>
              <li><img src="@/assets/img/currentPointo.png" alt=""><span>{{$t('front.stributor.myPoint')}}</span><span class="strnum">{{thousand(summary && summary.pointAmt || 0)}}</span></li>
              <li><img src="@/assets/img/progresso.png" alt=""><span>{{$t('front.stributor.progressMoney')}}</span><span class="strnum">{{thousand(summary && summary.waitPoinAmt || 0)}}</span></li>-->
              <li class="ptUser"><span>{{$t('front.stributor.bottomPT')}}</span><span class="strnum" v-if="summaryNew">{{thousand(summaryNew.partnerCnt)}}</span></li>
              <li class="ptUser"><span>{{$t('front.stributor.bottomU')}}</span><span class="strnum" v-if="summaryNew">{{thousand(summaryNew.botMemCnt)}}</span></li>
              <li class="ptUser"><span>{{$t('front.stributor.inUsers')}}</span><span class="strnum" v-if="summaryNew">{{thousand(summaryNew.totalMemCnt)}}</span></li>
              <li class="ptUser"><span>{{$t('front.stributor.todayJoin')}}</span><span class="strnum" v-if="summaryNew">{{thousand(summaryNew.todayJoincnt)}}</span></li>
              <li></li>
            </ul>
            <a class="saveBtnPT mb0" @click="onChangePartnerAddPopup" v-if="userData.partnerLevel !== 'PTN_5'">{{$t('front.stributor.bottommake')}}</a>
          </div>
          <!--PT tab 1-->
          <div class="tab-content" id="PTtab-1" :key="'PTtab-1'"  v-if="currentTab == 'PTtab-1'">
            <div class="pagenamew">
              <h3 class="pagename2">파트너{{$t('front.stributor.totalList')}}</h3>
            </div>
            <div class="datesearchPT">
              <date-filter :retail="true" @search="loadMainTable"
                            @update="onChangeDateTable"
                            :defaultDay="0"
                            :startDate="mainTableDate.startDate"
                            :endDate="mainTableDate.endDate"
                            :isOldYn="true"
                            :id="'main-date-checkbox1'"
                            @setOldYn="setOldYn" :oldYn="reqData.oldYn" />
            </div>
            <ul class="strbtnPT mb20">
              <li>{{$t('front.common.deposit')}}<span>{{thousand(total.userCashIn || 0)}}</span></li>
              <li>{{$t('front.common.withdrawal')}}<span>{{thousand(total.userCashOut || 0)}}</span></li>
              <li>{{$t('front.common.depositWithdrawal')}}<span>{{thousand(total.userCashResultAmt || 0)}}</span></li>
              <li>{{$t('front.stributor.m22')}}<span>{{thousand(total.betAmt || 0)}}</span></li>
              <li>{{$t('front.stributor.m23')}}<span>{{thousand(total.betAmtWin || 0)}}</span></li>
              <li>{{$t('front.stributor.winlose')}}<span>{{thousand(total.betResultAmt || 0)}}</span></li>
              <li class="saveBtnPT" @click="move=!move">{{$t('front.stributor.move')}}</li>
            </ul>
            <retail-main-table v-model:partnerObj=partnerLevelObject :list="list" :date="mainTableDate" :table="'main'"/>
          </div>
          <!--PT tab 2-->
          <div class="tab-content" id="PTtab-2" :key="'PTtab-2'"  v-if="currentTab == 'PTtab-2'">
            <div>
              <div class="pagenamew">
                <h3 class="pagename2 w100w">하부파트너목록</h3>
              </div>
              <div class="PTsch">
                <input type="text" :placeholder="$t('front.search.emptySearch')"/>
                <a @click="onSearch">
                  <img src="@/assets/img/search.png" alt=""/>
                </a>
                <ul class="btBtnlist">
                  <li @click="onChangePartnerLevel('')">전체</li>
                  <template v-for="item in partnerLevelList" :key="item.code">
                    <template v-if="item.code !== 'NORMAL'">
                      <li @click="onChangePartnerLevel(item.code)">{{item.codeName}}</li>
                    </template>
                  </template>
                </ul>
              </div>
              <table class="strTable">
                <colgroup>
                  <col width="10%">
                  <col width="10%">
                  <col width="10%">
                  <col width="12.5%">
                  <col width="10%">
                  <col width="10%">
                  <col width="12.5%">
                  <col width="12.5%">
                  <col width="12.5%">
                </colgroup>
                <thead>
                  <tr>
                    <th>아이디</th>
                    <th>닉네임</th>
                    <th>파트너등급</th>
                    <th>상위아이디/닉네임</th>
                    <th>카지노요율</th>
                    <th>슬롯요율</th>
                    <th>보유금</th>
                    <th>보유포인트</th>
                    <th>가입 일시</th>
                  </tr>
                </thead>
                <tbody>
                  <tr v-for="item in partnerList" :key="item.memId">
                    <td>{{item.memId}}</td>
                    <td>{{item.memNick}}</td>
                    <td>{{item.levelName}}</td>
                    <td>{{item.recommenderId}}/{{item.recommenderNick}}</td>
                    <td>{{item.casinoRate}}</td>
                    <td>{{item.slotRate}}</td>
                    <td>{{thousand(item.cashAmt)}}</td>
                    <td>{{thousand(item.pointAmt)}}</td>
                    <td>{{dateFormatAll(item.redDt)}}</td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>
          <!--PT tab 3-->
          <div class="tab-content" id="PTtab-3" :key="'PTtab-3'"  v-if="currentTab == 'PTtab-3'">
            <div>
              <div class="pagenamew">
                <h3 class="pagename2 w100w">소속회원목록</h3>
              </div>
              <div class="PTsch">
                <input type="text" :placeholder="$t('front.search.emptySearch')" v-model="filterText"/>
                <a @click="onSearch">
                  <img src="@/assets/img/search.png" alt=""/>
                </a>
              </div>
              <table class="strTable">
                <colgroup>
                  <col width="12.5%">
                  <col width="12.5%">
                  <col width="10%">
                  <col width="10%">
                  <col width="20%">
                  <col width="20%">
                  <col width="15%">
                </colgroup>
                <thead>
                  <tr>
                    <th>아이디</th>
                    <th>닉네임</th>
                    <th>카지노요율</th>
                    <th>슬롯요율</th>
                    <th>보유금</th>
                    <th>보유포인트</th>
                    <th>가입 일시</th>
                  </tr>
                </thead>
                <tbody>
                  <tr v-for="item in partnerList" :key="item.memId">
                    <td>{{item.memId}}</td>
                    <td>{{item.memNick}}</td>
                    <td>{{item.casinoRate}}</td>
                    <td>{{item.slotRate}}</td>
                    <td>{{thousand(item.cashAmt)}}</td>
                    <td>{{thousand(item.pointAmt)}}</td>
                    <td>{{dateFormatAll(item.redDt)}}</td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>
          <!--PT tab 4 유저정산내역-->
          <div class="tab-content" id="PTtab-4" :key="'PTtab-4'"  v-if="currentTab == 'PTtab-4'">
            <div class="pagenamew">
              <h3 class="pagename2">유저{{$t('front.stributor.totalList')}}</h3>
            </div>
            <div class="datesearchPT">
              <date-filter :retail="true" @search="loadMainTable"
                            @update="onChangeDateTable"
                            :defaultDay="0"
                            :startDate="mainTableDate.startDate"
                            :endDate="mainTableDate.endDate"
                            :isOldYn="true"
                            :id="'main-date-checkbox1'"
                            @setOldYn="setOldYn" :oldYn="reqData.oldYn" />
            </div>
            <ul class="strbtnPT mb20">
              <li>{{$t('front.common.deposit')}}<span>{{thousand(total.userCashIn || 0)}}</span></li>
              <li>{{$t('front.common.withdrawal')}}<span>{{thousand(total.userCashOut || 0)}}</span></li>
              <li>{{$t('front.common.depositWithdrawal')}}<span>{{thousand(total.userCashResultAmt || 0)}}</span></li>
              <li>{{$t('front.stributor.m22')}}<span>{{thousand(total.betAmt || 0)}}</span></li>
              <li>{{$t('front.stributor.m23')}}<span>{{thousand(total.betAmtWin || 0)}}</span></li>
              <li>{{$t('front.stributor.winlose')}}<span>{{thousand(total.betResultAmt || 0)}}</span></li>
              <li class="saveBtnPT" @click="move=!move">{{$t('front.stributor.move')}}</li>
            </ul>
            <retail-main-table v-model:partnerObj=partnerLevelObject :list="list" :date="mainTableDate" :table="'main'"/>
          </div>
          <!--PT tab 5 입금내역-->
          <div class="tab-content" id="PTtab-5" :key="'PTtab-5'"  v-if="currentTab == 'PTtab-5'">
            <div>
              <div class="pagenamew">
                <h3 class="pagename2 w100w">입금내역</h3>
              </div>
              <div class="PTsch">
                <div class="datesearchPT">
                  <date-filter :retail="true" @search="loadMainTable"
                                @update="onChangeDateTable"
                                :defaultDay="0"
                                :startDate="mainTableDate.startDate"
                                :endDate="mainTableDate.endDate"
                                :isOldYn="true"
                                :id="'main-date-checkbox1'"
                                @setOldYn="setOldYn" :oldYn="reqData.oldYn" />
                </div>
                <div class="searchPT">
                  <select>
                    <option value="selTotal" selected>전체</option>
                    <option value="selIn">입금</option>
                    <option value="selOut">출금</option>
                    <option value="selMngGive">관리자 지급</option>
                    <option value="selMngRe">관리자 회수</option>
                    <option value="selUpGive">상위 파트너 지급</option>
                    <option value="selUpRe">상위 파트너 회수</option>
                    <option value="selPoint">포인트전환</option>
                  </select>
                  <select>
                      <option value="memID" selected>아이디</option>
                      <option value="memNick">닉네임</option>
                      <option value="memUpper">상위유저</option>
                  </select>
                  <input type="text" :placeholder="$t('front.search.emptySearch')" v-model="filterText"/>
                  <a @click="onSearch">
                    <img src="@/assets/img/search.png" alt=""/>
                  </a>
                </div>
              </div>
              <table class="strTable">
                <colgroup>
                  <col width="10%">
                  <col width="10%">
                  <col width="17%">
                  <col width="19%">
                  <col width="19%">
                  <col width="10%">
                  <col width="15%">
                </colgroup>
                <thead>
                  <tr>
                    <th>아이디</th>
                    <th>닉네임</th>
                    <th>상위 파트너</th>
                    <th>처리 전 보유금</th>
                    <th>처리금액</th>
                    <th>종류</th>
                    <th>처리시각</th>
                  </tr>
                </thead>
                <tbody>
                  <tr>
                    <td>ddd</td>
                    <td>ddd</td>
                    <td>상위파트너 아이디 / 닉네임</td>
                    <td>123,456,789</td>
                    <td>123,456</td>
                    <td>입금</td>
                    <td>2023-03-27 19:35</td>
                  </tr>
                  <tr>
                    <td>ddd</td>
                    <td>ddd</td>
                    <td>상위파트너 아이디 / 닉네임</td>
                    <td>123,456,789</td>
                    <td>123,456</td>
                    <td>출금</td>
                    <td>2023-03-27 19:35</td>
                  </tr>
                  <tr>
                    <td>ddd</td>
                    <td>ddd</td>
                    <td>상위파트너 아이디 / 닉네임</td>
                    <td>123,456,789</td>
                    <td>123,456</td>
                    <td>관리자 지급</td>
                    <td>2023-03-27 19:35</td>
                  </tr>
                  <tr>
                    <td>ddd</td>
                    <td>ddd</td>
                    <td>상위파트너 아이디 / 닉네임</td>
                    <td>123,456,789</td>
                    <td>123,456</td>
                    <td>관리자 회수</td>
                    <td>2023-03-27 19:35</td>
                  </tr>
                  <tr>
                    <td>ddd</td>
                    <td>ddd</td>
                    <td>상위파트너 아이디 / 닉네임</td>
                    <td>123,456,789</td>
                    <td>123,456</td>
                    <td>상위 파트너 지급</td>
                    <td>2023-03-27 19:35</td>
                  </tr>
                  <tr>
                    <td>ddd</td>
                    <td>ddd</td>
                    <td>상위파트너 아이디 / 닉네임</td>
                    <td>123,456,789</td>
                    <td>123,456</td>
                    <td>상위 파트너 회수</td>
                    <td>2023-03-27 19:35</td>
                  </tr>
                  <tr>
                    <td>ddd</td>
                    <td>ddd</td>
                    <td>상위파트너 아이디 / 닉네임</td>
                    <td>123,456,789</td>
                    <td>123,456</td>
                    <td>포인트 전환</td>
                    <td>2023-03-27 19:35</td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>
          <!--PT tab 6 베팅내역-->
          <div class="tab-content" id="PTtab-6" :key="'PTtab-6'"  v-if="currentTab == 'PTtab-6'">
            <div>
              <div class="pagenamew">
                <h3 class="pagename2 w100w">베팅내역</h3>
              </div>
              <div class="PTsch">
                <div class="datesearchPT">
                  <date-filter :retail="true" @search="loadMainTable"
                                @update="onChangeDateTable"
                                :defaultDay="0"
                                :startDate="mainTableDate.startDate"
                                :endDate="mainTableDate.endDate"
                                :isOldYn="true"
                                :id="'main-date-checkbox1'"
                                @setOldYn="setOldYn" :oldYn="reqData.oldYn" />
                </div>
                <div class="searchPT">
                  <select>
                    <option value="selWin" selected>승</option>
                    <option value="selLose">패</option>
                    <option value="selTie">무</option>
                    <option value="selWait">대기</option>
                    <option value="selCan">취소</option>
                  </select>
                  <input type="text" :placeholder="$t('front.member.emptyMemId')" v-model="filterText"/>
                  <!--<input type="text" :placeholder="$t('front.search.emptySearch')" v-model="filterText"/>-->
                  <a @click="onSearch">
                    <img src="@/assets/img/search.png" alt=""/>
                  </a>
                </div>
                <div class="searchPT">
                  <select>
                    <option value="selCasino" selected>카지노</option>
                    <option value="selSlot">슬롯</option>
                  </select>
                  <h5>게임 종류</h5>
                  <select>
                    <option value="selGame01" selected>전체보기</option>
                    <option value="selGame02">에볼루션</option>
                    <option value="selGame03">프라그마틱플레이</option>
                    <option value="selGame04">드림 게이밍</option>
                    <option value="selGame05">비보게이밍 카지노</option>
                    <option value="selGame06">마이크로게이밍</option>
                    <option value="selGame07">오리엔탈 게이밍</option>
                    <option value="selGame08">이주기</option>
                    <option value="selGame09">CQ9 카지노</option>
                    <option value="selGame10">아시아 게이밍</option>
                    <option value="selGame11">빅게이밍</option>
                    <option value="selGame12">WM 카지노</option>
                    <option value="selGame13">두윈 카지노</option>
                    <option value="selGame14">HC-보타</option>
                  </select>
                </div>
              </div>
              <table class="strTable">
                <colgroup>
                  <col width="7%">
                  <col width="9%">
                  <col width="9%">
                  <col width="9%">
                  <col width="9%">
                  <col width="11%">
                  <col width="9%">
                  <col width="9%">
                  <col width="9%">
                  <col width="11%">
                  <col width="7%">
                </colgroup>
                <thead>
                  <tr>
                    <th>번호</th>
                    <th>아이디</th>
                    <th>게임 종류</th>
                    <th>게임구분</th>
                    <th>베팅날짜</th>
                    <th>처리 전 게임머니</th>
                    <th>베팅금</th>
                    <th>당첨금</th>
                    <th>윈루즈</th>
                    <th>처리 후 게임머니</th>
                    <th>상태</th>
                  </tr>
                </thead>
                <tbody>
                  <tr>
                    <td>1</td>
                    <td>ex ID</td>
                    <td>에볼루션</td>
                    <td>Casino</td>
                    <td>2023-03-27 20:01</td>
                    <td>123,456,789</td>
                    <td>5,000</td>
                    <td>5,000</td>
                    <td>10,000</td>
                    <td>123,456</td>
                    <td>승리</td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>
          <!-- PT tab 7 회원머니지급 -->
          <div class="tab-content" id="PTtab-7" :key="'PTtab-7'"  v-if="currentTab == 'PTtab-7'">
            <div class="pagenamew">
              <h3 class="pagename2">{{$t('front.give.title1')}}</h3>
            </div>
            <div id="userMoney">
              <ul>
                <li>
                  <select>
                    <option value="" disabled selected>{{$t('front.give.bottomPatner')}}</option>
                    <option>옵션1</option>
                    <option>옵션2</option>
                    <option>옵션3</option>
                  </select>
                </li>
                <li>
                  <select>
                    <option value="" disabled selected>{{$t('front.give.bottomPatner')}}</option>
                    <option>옵션1</option>
                    <option>옵션2</option>
                    <option>옵션3</option>
                  </select>
                </li>
                <li>{{$t('front.give.moneys')}}
                  <input type="text" :placeholder="$t('front.give.moneyInput')" />
                  <input type="text" :placeholder="$t('front.give.targetMoney')" readonly />
                </li>
                <li><a class="bgb">{{$t('front.give.give')}}</a></li>
                <li><a class="bgr">{{$t('front.give.back')}}</a></li>
              </ul>
            </div>
            <div class="pagenamew">
              <h3 class="pagename2">{{$t('front.give.title2')}}</h3>
            </div>
            <div class="datesearchPT">
              <date-filter :retail="true" @search="loadMainTable"
                            @update="onChangeDateTable"
                            :defaultDay="0"
                            :startDate="mainTableDate.startDate"
                            :endDate="mainTableDate.endDate"
                            :isOldYn="true"
                            :id="'main-date-checkbox1'"
                            @setOldYn="setOldYn" :oldYn="reqData.oldYn" />
              <ul>
                <li>
                  받은 아이디: <input type="text"/>
                </li>
                <li>
                  성공처리여부:
                  <select>
                    <option value="" selected>전체</option>
                    <option>성공</option>
                    <option>실패</option>
                  </select>
                </li>
                <li>
                  종류:
                  <select>
                    <option value=""  selected>전체</option>
                    <option>지급</option>
                    <option>회수</option>
                  </select>
                </li>
              </ul>
            </div>
            <div>
              <table class="strTable">
                <colgroup>
                  <col width="10%">
                  <col width="10%">
                  <col width="10%">
                  <col width="10%">
                  <col width="10%">
                  <col width="10%">
                  <col width="10%">
                  <col width="10%">
                  <col width="10%">
                  <col width="10%">
                </colgroup>
                <thead>
                  <tr>
                    <th>IDX</th>
                    <th>받은아이디</th>
                    <th>닉네임</th>
                    <th>내용</th>
                    <th>전송금액</th>
                    <th>처리 후 캐시</th>
                    <th>내 보유 캐시</th>
                    <th>전송일시</th>
                    <th>처리 여부</th>
                    <th>비고</th>
                  </tr>
                </thead>
                <tbody>
                  <tr>
                    <td>09</td>
                    <td>ex ID</td>
                    <td>ex Nickname</td>
                    <td>ex Detail</td>
                    <td>1,000,000</td>
                    <td>122,456,789</td>
                    <td>123,456,789</td>
                    <td>2023-03-28</td>
                    <td>O</td>
                    <td>ex etc.</td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>
          <!------------------------------------------------------------------------------->
          <div v-if="make" class="moveWrap">
            <div class="moveWraphead">
              <h3>파트너 생성</h3>
              <a @click="onChangePartnerAddPopup" class="close"><img src="@/assets/img/icon_cancel.svg" /></a>
            </div>
            <div class="moveWrapbody">
              <h4>파트너 정보</h4>
              <table>
                <colgroup>
                  <col width="20%"/>
                  <col width="30%"/>
                  <col width="20%"/>
                  <col width="30%"/>
                </colgroup>
                <tr>
                  <th>아이디</th>
                  <td><input class="in" name="newMemId" type="text" v-model="newPartner.memId" autocomplete="off" role="presentation"/></td>
                  <th>비밀번호</th>
                  <td><input class="in" name="newMemPass" type="password" v-model="newPartner.memPass" autocomplete="off"/></td>
                </tr>
                <tr>
                  <th>닉네임</th>
                  <td><input class="in" type="text" v-model="newPartner.memNick"/></td>
                  <th>출금 비밀번호</th>
                  <td><input class="in" type="password" v-model="newPartner.cashOutPass"/></td>
                </tr>
                <tr>
                  <th>생성파트너등급</th>
                  <td>{{newPartnerLevel.codeName}}</td>
                  <th>계좌정보</th>
                  <td class="accountInfo">
                    <bank-list :className="'in'" @onChange="onChangeBank"></bank-list>
                    <input class="in" type="text" v-model="newPartner.bankAcc"/>
                  </td>
                </tr>
                <tr>
                  <th>이름</th>
                  <td><input class="in" type="text" v-model="newPartner.memName"/></td>
                  <th>전화번호</th>
                  <td><input class="in" type="text" v-model="newPartner.memPhone"/></td>
                </tr>
              </table>
              <h4>게임별 요율설정</h4>
              <table>
                <tr>
                  <th :colspan="Object.keys(newPartner.rateGroup).length">{{$t('front.stributor.roll')}}(%)</th>
                  <th :colspan="Object.keys(newPartner.rateGroup).length">{{$t('front.stributor.lose')}}(%)</th>
                </tr>
                <tr>
                  <th v-for="(item, key) in newPartner.rateGroup" v-bind:key="item">
                    {{$t(`front.gnb.${key}`)}}
                  </th>
                  <th v-for="(item, key) in newPartner.rateGroup" v-bind:key="item">
                    {{$t(`front.gnb.${key}`)}}
                  </th>
                </tr>
                <tr>
                  <td v-for="(item) in newPartner.rateGroup" v-bind:key="item">
                    <a class="rbnt">최대값 : {{item.maxPointRate}}</a>
                    <a class="rbnt">최소값 : {{item.minPointRate}}</a>
                  </td>
                  <td v-for="(item) in newPartner.rateGroup" v-bind:key="item">
                    <a class="rbnt">최대값 : {{item.maxLoseRate}}</a>
                    <a class="rbnt">최소값 : {{item.minLoseRate}}</a>
                  </td>
                </tr>
                <tr>
                  <td v-for="(item, key) in newPartner.rateGroup" v-bind:key="item">
                    <input type="text" v-model="item[`${key}PR`]">
                  </td>
                  <td v-for="(item, key) in newPartner.rateGroup" v-bind:key="item">
                    <input type="text" v-model="item[`${key}LR`]">
                  </td>
                </tr>
              </table>
              <a class="btn" @click="onAddPartnerSubmit">{{$t('front.stributor.save')}}</a>
            </div>
            <!--retail-rate :rate="rate"></retail-rate-->
          </div>
          <div v-if="move" class="moveWrap">
            <div class="moveWraphead">
              <h3>요율조정</h3>
              <a @click="move=!move" class="close"><img src="@/assets/img/icon_cancel.svg" /></a>
            </div>
            <div class="moveWrapbody">
              <h4>내요율</h4>
              <table>
                <tr>
                  <th :colspan="Object.keys(commonCodeByOrder).length">{{$t('front.stributor.roll')}}(%)</th>
                  <th :colspan="Object.keys(commonCodeByOrder).length">{{$t('front.stributor.lose')}}(%)</th>
                </tr>
                <tr>
                  <th v-if="gameCount['casino']">{{$t('front.gnb.casino')}}</th>
                  <th v-if="gameCount['slot']">{{$t('front.gnb.slot')}}</th>
                  <th v-if="gameCount['minigame'] || gameCount['mini game']">{{$t('front.gnb.minigame')}}</th>
                  <th v-if="gameCount['casino']">{{$t('front.gnb.casino')}}</th>
                  <th v-if="gameCount['slot']">{{$t('front.gnb.slot')}}</th>
                  <th v-if="gameCount['minigame'] || gameCount['mini game']">{{$t('front.gnb.minigame')}}</th>
                </tr>
                <tr>
                  <td v-if="gameCount['casino']">{{myRate.casinoPR}}</td>
                  <td v-if="gameCount['slot']">{{myRate.slotPR}}</td>
                  <td v-if="gameCount['minigame'] || gameCount['mini game']">{{myRate.miniPR}}</td>
                  <td v-if="gameCount['casino']">{{myRate.casinoLR}}</td>
                  <td v-if="gameCount['slot']">{{myRate.slotLR}}</td>
                  <td v-if="gameCount['minigame'] || gameCount['mini game']">{{myRate.miniLR}}</td>
                </tr>
              </table>
              <h4>하부회원 요율조정</h4>
              <div class="scroll">
                <table>
                  <tr>
                    <th rowspan="2">{{$t('front.common.memId')}}</th>
                    <th rowspan="2">{{$t('front.common.nickName')}}</th>
                    <th :colspan="Object.keys(commonCodeByOrder).length">{{$t('front.stributor.roll')}}(%)</th>
                    <th :colspan="Object.keys(commonCodeByOrder).length">{{$t('front.stributor.lose')}}(%)</th>
                  </tr>
                  <tr>
                    <th v-if="gameCount['casino']">{{$t('front.gnb.casino')}}</th>
                    <th v-if="gameCount['slot']">{{$t('front.gnb.slot')}}</th>
                    <th v-if="gameCount['minigame'] || gameCount['mini game']">{{$t('front.gnb.minigame')}}</th>
                    <th v-if="gameCount['casino']">{{$t('front.gnb.casino')}}</th>
                    <th v-if="gameCount['slot']">{{$t('front.gnb.slot')}}</th>
                    <th v-if="gameCount['minigame'] || gameCount['mini game']">{{$t('front.gnb.minigame')}}</th>
                  </tr>
                  <template v-if="myChildrenRate.length">
                    <template v-for="item in myChildrenRate" :key="item.memId">
                      <tr>
                        <td>{{item.memId}}</td>
                        <td>{{item.memNick}}</td>
                        <td v-if="gameCount['casino']"><input @change="onChangeChildrenRate(item)" :class="{'error': item.casinoPRError}" type="text" v-model="item.casinoPR"></td>
                        <td v-if="gameCount['slot']"><input @change="onChangeChildrenRate(item)" :class="{'error': item.slotPRError}" type="text" v-model="item.slotPR"></td>
                        <td v-if="gameCount['minigame']"><input @change="onChangeChildrenRate(item)" :class="{'error': item.miniPRError}" type="text" v-model="item.miniPR"></td>
                        <td v-if="gameCount['casino']"><input @change="onChangeChildrenRate(item)" :class="{'error': item.casinoLRError}" type="text" v-model="item.casinoLR"></td>
                        <td v-if="gameCount['slot']"><input @change="onChangeChildrenRate(item)" :class="{'error': item.slotLRError}" type="text" v-model="item.slotLR"></td>
                        <td v-if="gameCount['minigame']"><input @change="onChangeChildrenRate(item)" :class="{'error': item.miniLRError}" type="text" v-model="item.miniLR"></td>
                      </tr>
                    </template>
                  </template>
                </table>
              </div>
              <p>하부회원의 최대 요율은 내 상위요율을 넘을 수 없습니다. 최소요율은 해당회원의 하부 최대 요율보다 낮을 수 없습니다.</p>
              <a class="btn" @click="onUpdateChildrenRate">{{$t('front.stributor.save')}}</a>
            </div>
            <retail-detail :className="'mb60'" :list="detailList"></retail-detail>
          </div>
        </div>
      </div>
    </section>
  </div>
</template>

<script>
import {
  getRetailMyInfo, getRetailMyMemList,
  getRetailSummary, partnerJoin, partnerLevels, partnerRates, retailMemRate
  , retailMyCalculate, retailUpdate
} from '@/api/retail'
import DateFilter from '@/components/ui/DateFilter'
import RetailDetail from '@/components/common/retailDetail'
import RetailMainTable from '@/components/member/stributor/RetailMainTable.vue'
import { getDateStr, thousand } from '@/libs/utils'
import { addDays } from 'date-fns'
import { mapState } from 'vuex'
import BankList from '@/components/ui/BankList.vue'
export default {
  name: 'striNewPage',
  components: { BankList, RetailMainTable, RetailDetail, DateFilter },
  computed: {
    ...mapState([
      'userData',
      'gameCount',
      'commonCodeByOrder',
      'commonCodeByCode',
      'partnerLevelList'
    ])
  },
  watch: {
    currentTab () {

    }
  },
  data () {
    return {
      currentTab: 'PTtab-5',
      summary: {},
      rate: {},
      detailInfo: {},
      detailList: {},
      loginMemList: [],
      memList: [],
      memberDetailList: {},
      memberRate: {},
      isMemberDetail: false,
      isMemberRate: false,
      moret: false,
      mainTableDate: {
        startDate: getDateStr(addDays(new Date(), 0), 'yyyy-MM-dd 00:00:00'),
        endDate: getDateStr(addDays(new Date(), 0), 'yyyy-MM-dd 23:59:59')
      },
      detailInfoDate: {
        startDate: getDateStr(addDays(new Date(), 0), 'yyyy-MM-dd 00:00:00'),
        endDate: getDateStr(addDays(new Date(), 0), 'yyyy-MM-dd 23:59:59')
      },
      reqData: {
        searchType: 'OUTER',
        chkTodayYn: 'N',
        startDate: '',
        endDate: '',
        offset: -1,
        oldYn: 'N'
      },
      list: [],
      move: false,
      make: false,
      myRate: {},
      myChildrenRate: [],
      newPartner: {
        memId: '',
        memPass: '',
        memNick: '',
        memPhone: '',
        bank: '',
        bankAcc: '',
        cashOutPass: '',
        rateGroup: {}
      },
      defaultNewPartner: {
        memId: '',
        memPass: '',
        memNick: '',
        memPhone: '',
        bank: '',
        bankAcc: '',
        cashOutPass: '',
        rateGroup: {}
      },
      rateMaxMinList: [],
      newPartnerLevel: {},
      total: {},
      searchType: 'N',
      searchPartnerLevel: '',
      partnerLevelList: [],
      partnerList: [],
      partnerLevelObject: null,
      summaryNew: null
    }
  },
  async created () {
    this.emitter.emit('Loading', true)
    this.getPartnerLevels()
    this.reqData.startDate = getDateStr(new Date(this.mainTableDate.startDate))
    this.reqData.endDate = getDateStr(new Date(this.mainTableDate.endDate))

    await this.loadMainTable()
    this.getSummary()
    await this.getMyInfo()
    this.onLoadRate()
    await this.getPartnerRateForNewPartner()
    this.newPartnerLevel = this.partnerLevelList[0]
    this.emitter.emit('Loading', false)
  },
  methods: {
    onChangeTab (type) {
      if (type === 'PTtab-2') {
        if (this.userData.partnerLevel === 'PTN_5') {
          return false
        }
        this.searchType = 'P'
      } else if (type === 'PTtab-3') {
        this.searchType = 'N'
      }
      this.currentTab = type
      this.getPartnerList()
    },
    thousand,
    onChangePartnerLevel (partnerLevel) {
      this.searchPartnerLevel = partnerLevel
      this.getPartnerList()
    },
    getPartnerList () {
      const params = {
        memId: '',
        partnerLevel: this.searchPartnerLevel,
        searchType: this.searchType
      }
      console.log('[REQ]getRetailMyMemList : ', params)
      getRetailMyMemList(params).then(res => {
        console.log('[RES]getRetailMyMemList : ', res.data)
        const result = res.data
        if (result.resultCode === '0') {
          console.log(result.list)
          this.partnerList = result.data.list
        }
      })
    },
    setOldYn (data) {
      console.log('table : ', data)
      this.reqData.oldYn = data
    },
    getPartnerLevels () {
      partnerLevels({}).then(res => {
        const result = res.data
        if (result.resultCode === '0') {
          console.log('partnerLevel : ', result.data)
          this.partnerLevelList = result.data.list
          this.newPartnerLevel = result.data.list[0]

          const partnerObj = {}
          for (let i = 0; i < this.partnerLevelList.length; i++) {
            const item = this.partnerLevelList[i]
            const code = item.code
            const codeName = item.codeName

            if (!partnerObj[code]) {
              partnerObj[code] = codeName
            }
          }

          console.log(partnerObj)

          this.partnerLevelObject = partnerObj
        }
      })
    },
    onChangePartnerAddPopup () {
      this.make = !this.make
      if (this.make) {
        const list = this.rateMaxMinList
        for (let i = 0; i < list.length; i++) {
          const item = list[i]
          const vendorCode = item.vendorCode
          let groupCode = this.commonCodeByCode[vendorCode].groupCode
          if (groupCode === 'minigame') {
            groupCode = 'mini'
          }
          if (groupCode) {
            this.newPartner.rateGroup[groupCode] = {
              ...item
            }
            this.newPartner.rateGroup[groupCode][`${groupCode}PR`] = '0.0'
            this.newPartner.rateGroup[groupCode][`${groupCode}LR`] = '0.0'
          }
        }
        console.log(this.newPartner.rateGroup)
      }
    },
    getPartnerRateForNewPartner () {
      return partnerRates({}).then(res => {
        console.log('[partnerRates] : ', res)
        const result = res.data
        if (result.resultCode === '0') {
          const list = result.data.rateMaxMinList
          this.rateMaxMinList = list
        }
      })
    },
    onAddPartnerSubmit () {
      console.log(this.newPartner)
      const reqData = { ...this.newPartner, rateGroup: {} }

      for (const groupCode in this.newPartner.rateGroup) {
        const item = this.newPartner.rateGroup[groupCode]
        const pr = item[`${groupCode}PR`]
        if (pr) {
          if (Number(item.maxPointRate) < Number(pr) || Number(item.minPointRate) > Number(pr)) {
            this.onAlert('warningart', `front.stributor.${groupCode}RollingError`)
            return false
          }
        } else {
          this.onAlert('warningart', `front.stributor.${groupCode}RollingError`)
          return false
        }
        const lr = item[`${groupCode}LR`]
        if (lr) {
          if (Number(item.maxLoseRate) < Number(lr) || Number(item.minLoseRate) > Number(lr)) {
            this.onAlert('warningart', `front.stributor.${groupCode}LoseError`)
            return false
          }
        } else {
          this.onAlert('warningart', `front.stributor.${groupCode}LoseError`)
          return false
        }

        reqData.rateGroup[`${groupCode}PR`] = pr
        reqData.rateGroup[`${groupCode}LR`] = lr
      }

      if (!reqData.memId) {
        this.onAlert('warningart', 'front.member.emptyMemId')
        return false
      }
      if (!reqData.memPass) {
        this.onAlert('warningart', 'front.member.emptyMemPass')
        return false
      }
      if (!reqData.memNick) {
        this.onAlert('warningart', 'front.member.emptyMemNick')
        return false
      }
      if (!reqData.cashOutPass) {
        this.onAlert('warningart', 'front.member.emptyCashOutPass')
        return false
      }
      if (!reqData.bank) {
        this.onAlert('warningart', 'front.member.emptyBankName')
        return false
      }
      if (!reqData.bankAcc) {
        this.onAlert('warningart', 'front.member.emptyBankNumber')
        return false
      }
      if (!reqData.memName) {
        this.onAlert('warningart', 'front.member.emptyMemName')
        return false
      }
      if (!reqData.memPhone) {
        this.onAlert('warningart', 'front.member.emptyMemTel')
        return false
      }

      console.log(reqData)

      partnerJoin(reqData).then(res => {
        console.log(res)
        const result = res.data
        if (result.resultCode === '0') {
          this.onCheck('하위파트너가 정상적으로 생성되었습니다.')
          this.onChangePartnerAddPopup()
          this.newPartner = { ...this.defaultNewPartner }
        } else {
          this.onAlert('warningart', '생성에 실패하였습니다. 다시 시도해주세요')
        }
      })
    },
    onChangeBank (value) {
      this.newPartner.bank = value
    },
    async onUpdateChildrenRate () {
      const rateList = this.myChildrenRate

      for (let i = 0, iLen = rateList.length; i < iLen; i++) {
        const item = rateList[i]
        if (item.casinoLRError ||
            item.casinoPRError ||
            item.slotLRError ||
            item.slotPRError ||
            item.miniLRError ||
            item.miniPRError
        ) {
          return
        }
      }

      const confirm = await this.onConfirm('front.common.confirmSave')
      if (confirm) {
        console.log(rateList)
        const param = {
          siteId: '',
          memId: '',
          rateList: rateList
        }

        retailUpdate(param).then(res => {
          const data = res.data
          if (data.resultCode === '0') {
            this.onCheck('front.recommender.complete')
            this.onLoadRate()
          }
        })
      }
    },
    onChangeChildrenRate (item) {
      console.log(item)

      const casinoPR = Number(item.casinoPR)
      if (casinoPR || casinoPR === 0) {
        const maxCasinoPR = Number(item.maxCasinoPR)
        const minCasinoPR = Number(item.minCasinoPR)
        if (maxCasinoPR < casinoPR || minCasinoPR > casinoPR) {
          item.casinoPRError = true
        } else {
          item.casinoPRError = false
        }
      }

      const slotPR = Number(item.slotPR)
      if (slotPR || slotPR === 0) {
        const maxSlotPR = Number(item.maxSlotPR)
        const minSlotPR = Number(item.minSlotPR)
        if (maxSlotPR < slotPR || minSlotPR > slotPR) {
          item.slotPRError = true
        } else {
          item.slotPRError = false
        }
      }

      const miniPR = Number(item.miniPR)
      if (miniPR || miniPR === 0) {
        const maxMiniPR = Number(item.maxMiniPR)
        const minMiniPR = Number(item.minMiniPR)
        if (maxMiniPR < miniPR || minMiniPR > miniPR) {
          item.miniPRError = true
        } else {
          item.miniPRError = false
        }
      }

      const casinoLR = Number(item.casinoLR)
      if (casinoLR || casinoLR === 0) {
        const maxCasinoLR = Number(item.maxCasinoLR)
        const minCasinoLR = Number(item.minCasinoLR)
        if (maxCasinoLR < casinoLR || minCasinoLR > casinoLR) {
          item.casinoLRError = true
        } else {
          item.casinoLRError = false
        }
      }

      const slotLR = Number(item.slotLR)
      if (slotLR || slotLR === 0) {
        const maxSlotLR = Number(item.maxSlotLR)
        const minSlotLR = Number(item.minSlotLR)
        if (maxSlotLR < slotLR || minSlotLR > slotLR) {
          item.slotLRError = true
        } else {
          item.slotLRError = false
        }
      }

      const miniLR = Number(item.miniLR)
      if (miniLR || miniLR === 0) {
        const maxMiniLR = Number(item.maxMiniLR)
        const minMiniLR = Number(item.minMiniLR)
        if (maxMiniLR < miniLR || minMiniLR > miniLR) {
          item.miniLRError = true
        } else {
          item.miniLRError = false
        }
      }
    },
    onLoadRate () {
      retailMemRate({}).then(res => {
        const data = res.data
        if (data.resultCode === '0') {
          console.log('1111', data)
          this.myRate = data.data.myCategoryRate
          this.myChildrenRate = data.data.botCategoryRateList
        }
      })
    },
    onChangeDateTable (value) {
      console.log(value)
      this.reqData.startDate = getDateStr(new Date(value.startDate))
      this.reqData.endDate = getDateStr(new Date(value.endDate))
    },
    onChangeDateInfo (value) {
      console.log(value)
      this.detailInfoDate.startDate = getDateStr(new Date(value.startDate))
      this.detailInfoDate.endDate = getDateStr(new Date(value.endDate))
    },
    async loadMainTable (value) {
      if (!value) {
        this.emitter.emit('Loading', true)
        const params = {
          ...this.reqData
        }
        console.log(params)

        this.mainTableDate.startDate = this.reqData.startDate
        this.mainTableDate.endDate = this.reqData.endDate

        const today = new Date()
        if (params.endDate === getDateStr(today)) {
          params.chkTodayYn = 'Y'
        } else {
          params.chkTodayYn = 'N'
        }

        console.log('[req][retailMyCalculate] : ', params)
        await retailMyCalculate(params).then(res => {
          console.log('[res][retailMyCalculate] : ', res)
          const data = res.data
          if (data.resultCode === '0') {
            this.list = data.data.outSearchList
            this.total = data.data.outSearchTotal
          }

          this.emitter.emit('Loading', false)
        })
      }
    },
    loadList () {
      this.getMyInfo()
    },
    getSummary () {
      getRetailSummary({}).then(response => {
        const data = response.data
        if (data.resultCode === '0') {
          this.summary = data.data.summary
          this.summaryNew = data.data.summaryNew
          console.log('summary : ', this.summary)
          console.log('summaryNew : ', this.summary)
        }
      })
    },
    async getMyInfo () {
      this.emitter.emit('Loading', true)
      getRetailMyInfo(this.detailInfoDate).then(async response => {
        const data = response.data
        if (data.resultCode === '0') {
          console.log(data)
          this.detailInfo = data.data.mycashinfo
          this.detailList = {}
          console.log(data.data.myBetPointinfo)
          data.data.myBetPointinfo.forEach(item => {
            const groupCode = item.groupCode || 'mg'
            const gameType = item.gameType
            if (groupCode) {
              if (!this.detailList[groupCode]) {
                this.detailList[groupCode] = []
              }

              if (this.commonCodeByCode[gameType]) {
                this.detailList[groupCode].push(item)
              }
            }
          })
        }
        this.emitter.emit('Loading', false)
      })
    }
  }
}
</script>

<style scoped src="@/styles/common.css"></style>
<style scoped src="@/styles/subcommon.css"></style>
<style scoped src="@/styles/striNew.css"></style>
